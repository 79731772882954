<template lang="pug">
include ../pug/svg
section#features
	Form.wrapper(ref="featureForm" :validation-schema="featureSchema" v-slot="{ values, errors, meta }")
		div.fieldset.c3
			h4 Usernames
			FieldBoolean.c2(
				v-model="usernames"
				label="Usernames"
				:yesLabel="checkedLabel"
				:noLabel="uncheckedLabel"
				@fieldUpdated="enableCheck")
			//-FieldBoolean.c2(
				v-model="boolTempPasswordAutomate"
				label="Temporary Password Generation (CSV)"
				yesLabel="Automated"
				noLabel="Manual"
				@fieldUpdated="enableCheck")
			//-FieldBoolean.c2(
				v-model="tempPasswordDisclose"
				label="Temporary Password Disclosure"
				yesLabel="Active"
				noLabel="Disabled"
				@fieldUpdated="enableCheck")
		div.fieldset.c3
			h4 Comms
			FieldBoolean.c2(
				v-model="messages"
				:label="terminology('comms','message','plural')"
				:yesLabel="checkedLabel"
				:noLabel="uncheckedLabel"
				@fieldUpdated="enableCheck")
			FieldBoolean.c2(
				v-model="calls"
				:label="terminology('comms','call','plural')"
				:yesLabel="checkedLabel"
				:noLabel="uncheckedLabel"
				@fieldUpdated="enableCheck")
		//-FieldCheckbox.c3(
			v-model="checkboxMessages"
			:options="userRoleOptions"
			:legend="terminology('comms','message','plural')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		//-FieldCheckbox.c3(
			v-model="checkboxCalls"
			:options="userRoleOptions"
			:legend="terminology('comms','call','plural')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxMedia"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('media','media','plural')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxPeople"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('user','user','plural')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxCourseAdd"
			:options="userRoleOptionsNonLearner"
			:legend="'Add ' + terminology('course','course','plural')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxCourseArchive"
			:options="userRoleOptionsNonLearner"
			:legend="'Archive ' + terminology('course','course','plural')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxCourseContent"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('course','course','singular') + ' /  Content'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxCourseAccess"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('course','course','singular') + ' / Access'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxCourseSettings"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('course','course','singular') + ' / Settings'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxCourseActivity"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('course','course','singular') + ' / Activity'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxThemeStyle"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('theme','theme','singular') + ' / Style'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxThemeFont"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('theme','theme','singular') + ' / Fonts'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxThemeColour"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('theme','theme','singular') + ' / Colours'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxThemeTerminology"
			:options="userRoleOptionsNonLearner"
			:legend="terminology('theme','theme','singular') + ' / Terminology'"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		//-FieldCheckbox.c3(
			v-model="checkboxAccountProfile"
			:options="userRoleOptions",
			legend="Account / Profile"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
		FieldCheckbox.c3(
			v-model="checkboxAccountOrganisation"
			:options="userRoleOptionsNonLearner",
			:legend="'Account / ' + terminology('organisation','organisation','singular')"
			:checkedLabel="checkedLabel",
			:uncheckedLabel="uncheckedLabel",
			@fieldUpdated="enableCheck")
	span.req Required
</template>

<script>
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import FieldBoolean from '../components/FieldBoolean';
import FieldCheckbox from '../components/FieldCheckbox';
import { mapFields } from 'vuex-map-fields';	

export default {
	name: 'ManageAccountFeature',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	components: {
		Form,
		FieldBoolean,
		FieldCheckbox,
	},
	data() {
		const featureSchema = Yup.object().shape({});
		
		return {
			featureSchema,
			checkedLabel: 'Feature enabled',
			uncheckedLabel: 'Feature disabled',
		}
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('organisation', 'organisation', 'singular'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},
		});		
	},
	computed: {
		...mapFields('gui', { // get/set vuex store (aliased)
			usernames: 'feature.usernames',
			//tempPasswordDisclose: 'feature.tempPassword.disclose',
			//tempPasswordAutomate: 'feature.tempPassword.automate',
			// menu items
			messages: 'feature.messages',
			calls: 'feature.calls',
			media: 'feature.media',
			people: 'feature.people',
			courseAdd: 'feature.course.add',
			courseArchive: 'feature.course.archive',
			courseContent: 'feature.course.content',
			courseAccess: 'feature.course.access',
			courseSettings: 'feature.course.settings',
			courseActivity: 'feature.course.activity',
			themeStyle: 'feature.theme.style',
			themeFont: 'feature.theme.font',
			themeColour: 'feature.theme.colour',
			themeTerminology: 'feature.theme.terminology',
			accountProfile: 'feature.account.profile',
			accountOrganisation: 'feature.account.organisation',
			//accountFeatures: 'feature.account.features', // should not be changed
		}),
		...mapFields('users', ['userRoles']),
		userRoleOptions() {
			return this.userRoles.map(role => ({
				value: role.slug,
				option: this.terminology('user', role.slug, 'singular'),
			})).reverse();
		},
		userRoleOptionsNonLearner() {
			return this.userRoleOptions.filter(o => o.value !== 'learner');
		},
		// converts feature role array to role objects
		checkboxMessages: {
			get() { return this.arrToObjArr(this.messages); },
			set(option) { this.messages = option.map(o => o.value); },
		},
		checkboxCalls: {
			get() { return this.arrToObjArr(this.calls); },
			set(option) { this.calls = option.map(o => o.value); },
		},
		checkboxMedia: {
			get() { return this.arrToObjArr(this.media); },
			set(option) { this.media = option.map(o => o.value); },
		},
		checkboxPeople: {
			get() { return this.arrToObjArr(this.people); },
			set(option) { this.people = option.map(o => o.value); },
		},
		checkboxCourseAdd: {
			get() { return this.arrToObjArr(this.courseAdd); },
			set(option) { this.courseAdd = option.map(o => o.value); },
		},
		checkboxCourseArchive: {
			get() { return this.arrToObjArr(this.courseArchive); },
			set(option) { this.courseArchive = option.map(o => o.value); },
		},
		checkboxCourseContent: {
			get() { return this.arrToObjArr(this.courseContent); },
			set(option) { this.courseContent = option.map(o => o.value); },
		},
		checkboxCourseAccess: {
			get() { return this.arrToObjArr(this.courseAccess); },
			set(option) { this.courseAccess = option.map(o => o.value); },
		},
		checkboxCourseSettings: {
			get() { return this.arrToObjArr(this.courseSettings); },
			set(option) { this.courseSettings = option.map(o => o.value); },
		},
		checkboxCourseActivity: {
			get() { return this.arrToObjArr(this.courseActivity); },
			set(option) { this.courseActivity = option.map(o => o.value); },
		},
		checkboxThemeStyle: {
			get() { return this.arrToObjArr(this.themeStyle); },
			set(option) { this.themeStyle = option.map(o => o.value); },
		},
		checkboxThemeFont: {
			get() { return this.arrToObjArr(this.themeFont); },
			set(option) { this.themeFont = option.map(o => o.value); },
		},
		checkboxThemeColour: {
			get() { return this.arrToObjArr(this.themeColour); },
			set(option) { this.themeColour = option.map(o => o.value); },
		},
		checkboxThemeTerminology: {
			get() { return this.arrToObjArr(this.themeTerminology); },
			set(option) { this.themeTerminology = option.map(o => o.value); },
		},
		checkboxAccountProfile: {
			get() { return this.arrToObjArr(this.accountProfile); },
			set(option) { this.accountProfile = option.map(o => o.value); },
		},
		checkboxAccountOrganisation: {
			get() { return this.arrToObjArr(this.accountOrganisation); },
			set(option) { this.accountOrganisation = option.map(o => o.value); },
		},
		//
		/*boolUsernames: {
			get() {
				return this.usernames;
			},
			set(option) {
				this.usernames = option;
				
				if (option === false) {
					this.tempPasswordDisclose = false;
					this.tempPasswordAutomate = false;
				}
			},
		},
		boolTempPasswordAutomate: {
			get() {
				return !this.usernames ? false : this.tempPasswordAutomate;
			},
			set(option) {
				this.tempPasswordAutomate = option;
				this.tempPasswordDisclose = true;
			},
		},*/
	},
	methods: {
		arrToObjArr(feature) {
			return feature.map(roleSlug => {
				const role = this.userRoles.find(r => r.slug === roleSlug);
				return {
					value: role.slug,
					option: this.terminology('user', role.slug, 'singular'),
				};
			});
		},
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		enableCheck() {
			const meta = this.$refs.featureForm.getMeta();
			this.$emit('storeUpdated', {
				screen: 'feature',
				valid: meta.valid,
			});
		},
	},
	watch: {
		cancelled: {
			// parent triggered cancel
			handler(val) {
				if (!val) { // store reset complete
					const form = this.$refs.featureForm;
					const errors = form.getErrors();
					
					for(const field in form.getValues()) {
						form.setFieldTouched(field, false);
						
						if (field in errors) {
							form.setFieldError(field, '-');					
						}
					}
				}
			},
			deep: true,
		},
	},
}
</script>

<style lang="scss">
</style>
