<template lang="pug">
fieldset.checkbox
	legend {{legend}}
	div.field(v-for="(option, index) in options" :index="index" :key="option")
		input(type="checkbox" v-model="value" :value="option" @change="fieldUpdated" :disabled="disabled")
		label {{option.option}}
		span.on(v-if="value.find(v => v.value == option.value)") {{checkedLabel}}
		span.off(v-else) {{uncheckedLabel}}
</template>

<script>
export default {
	name: 'FieldCheckbox',
	props: {
		modelValue: {
			type: Array,
			required: true,
		},
		options: {
			type: Array,
			required: true,
			validator: (value) => {
				const hasOptionKey = value.every((option) => Object.keys(option).includes('option'));
				const hasValueKey = value.every((option) => Object.keys(option).includes('value'));
				
				return hasOptionKey && hasValueKey;
			},
		},
		legend: {
			type: String,
			default: '',
		},
		checkedLabel: {
			type: String,
			default: 'Yes',
		},
		uncheckedLabel: {
			type: String,
			default: 'No',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['fieldUpdated','update:modelValue'],
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
	},
	methods: {
		fieldUpdated() {
			this.$emit('fieldUpdated');
		},
	},
}
</script>

<style lang="scss">
div.fieldset,
fieldset.checkbox {
	display: flex;
	flex-wrap: wrap;
	//justify-content: space-between;
	margin-bottom: 10px;
	padding: 15px 15px 15px 15px;
	border: 1px solid #CCC;
	border-radius: 8px;
	.field {
		margin-bottom: 15px;
	}
	&.c2 {		
		.field {
			width: calc(50% - 10px);
		}	
	}
	&.c3 {		
		.field {
			width: calc((100% / 3) - 10px);
			&:nth-of-type(3n+1),
			&:nth-of-type(3n+2) {
				margin-right: 15px;
			}
		}	
	}
}
div.fieldset h4,
fieldset.checkbox legend {	
	float: left;
	width: 100%;
	margin-bottom: 20px;
	font-family: var(--sub-fam);
	font-weight: var(--sub-wgt);
	text-transform: var(--sub-uc);
}
</style>
